@font-face {
	font-family: 'Montserrat-Bold';
	src: url('/fonts/Montserrat-Bold.eot');
	src: local('Montserrat-Bold'), local('Montserrat-Bold'),
		url('/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Bold.woff') format('woff'),
		url('/fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-LightItalic';
	src: url('/fonts/Montserrat-LightItalic.eot');
	src: local('Montserrat-LightItalic'), local('Montserrat-LightItalic'),
		url('/fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-LightItalic.woff') format('woff'),
		url('/fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-Regular';
	src: url('/fonts/Montserrat-Regular.eot');
	src: local('Montserrat-Regular'), local('Montserrat-Regular'),
		url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Montserrat-Regular.woff') format('woff'),
		url('/fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}