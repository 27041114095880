@import 'fonts.less';

@MontserratBold: 'Montserrat-Bold', sans-serif;
@MontserratLightItalic: 'Montserrat-LightItalic', sans-serif;
@MontserratRegular: 'Montserrat-Regular', sans-serif;

html{
    scroll-behavior: smooth;
}
body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    /*background: url(../images/bg.png) no-repeat bottom left #000;*/
    /*background-size: 85%;*/
    /*margin: 0;*/
    .row{
        margin: 0 0 0 0;
    }
    .MuiModal-backdrop{
        background-color: rgba(0, 0, 0, 0.8);
    }
    .MuiDialog-paper{
        border-radius: 0;
    }
    #message{
        .MuiDialog-paper{
            max-width: 640/16vw;
            width: 100%;
            height: auto;
            background-color: #FFFFFF;
            border-radius: 10px
        }
        .MuiDialogContent-root{
            width: 600/16vw;
            margin: 0 auto;
            padding: 0 0 0 0;
        }
        .modal-i-close{
            position: absolute;
            width: 15px;
            height: 15px;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
        h1{
            width: 100%;
            //font-family: @MyriadProBold;
            font-size: 40px;
            line-height: 1;
            color: #000000;
            text-align: center;
            text-transform: none;
            margin: 0 0 30px 0;
            padding: 0;
        }
        p{
            font-family: @MontserratRegular;
            font-size: 33/16vw;
            line-height: 1.2;
            color: #000000;
            text-align: center;
            margin: 0;
            padding: 50/16vw 0;
        }
        /*        img{
                    width: 5.20834vw;
                    height: 5.20834vw;
                    margin: 0 0 2.78vw 0;
                }
                button{
                    display: block;
                    //background-color: #E20019;
                    width: 19.45vw;
                    height: 3.125vw;
                    //font-family: @robotoBold;
                    font-size: 1.12vw;
                    line-height: 0.1;
                    color: #FFFFFF;
                    text-transform: none;
                    text-align: center;
                    margin: 1.73612vw auto 0 auto;
                    padding: 0;
                    box-shadow: none;
                    border-radius: 1.389vw;
                }*/
    }
    /*    .MuiTooltip-tooltip{
            //background-color: #FFFFFF!important;
            color: #FF0000;
            max-width: 10.4167vw;
        }*/
    .block-input.error, .block-select.error{
        border-color: red!important;
        label{
            color: red!important;
            font-weight: bold;
        }
    }
    .block-input, .block-select{
        display: block;
        width: 594/16vw;
        height: 70/16vw;
        background-color: transparent;
        padding: 0;
        margin: 65/16vw 0 230/16vw 0;
        border: 6/16vw solid #ffffff;
        border-radius: 0px;
        .MuiInput-root{
            border: none;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            &:after, &:hover, &:not(.Mui-disabled, .Mui-error), &:before{
                border: none;
            }
        }
        .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
            border: none;
        }
        .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled{
            background: transparent;
            color: #9D9D9D;
            text-transform: none;
            transform: none;
            top: -25px;
            padding: 0 0 0 0;
            margin: 0 0 0 0;
        }
        .MuiOutlinedInput-notchedOutline{
            border: none;
        }
        label{
            background: transparent;
            width: 594/16vw;
            max-width: 594/16vw;
            //font-family: @MyriadProIt;
            font-style: italic;
            font-size: 20px;
            line-height: 30px;
            color: #c3c1d3;
            text-transform: none;
            text-align: left;
            transform: translate(0, 0);
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            z-index: 1;
        }
        input{
            background-color: transparent;
            width: 522/16vw;
            height: 57/16vw;
            font-family: @MontserratRegular;
            font-size: 33/16vw;
            line-height: 57/16vw;
            color: #FFFFFF;
            text-transform: none;
            text-align: left;
            padding: 0 30/16vw;
            margin: 0;
            border-radius: 0px;
        }
    }
    .block-checkbox{
        display: flex;
        padding: 0 0 0 0;
        margin: 35px 0 0 0;
        .checkbox{
            display: inline-block;
            width: 27px;
            height: 27px;
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            input{
                width: 27px;
                height: 27px;
                position: relative;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
                &:checked + label:before{
                    content: '';
                    //background: url(/images/i_checkbox_checked.png) no-repeat;
                    background-size: contain;
                    display: inline-block;
                    width: 27px;
                    height: 27px;
                }
            }
            label{
                width: 27px;
                height: 27px;
                position: relative;
                top: -33px;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                max-width: 100%;
                &:before{
                    content: '';
                    //background: url(/images/i_checkbox.png) no-repeat;
                    background-size: contain;
                    display: inline-block;
                    width: 27px;
                    height: 27px;
                }
            }
        }
        label{
            display: inline-block;
            max-width: 435px;
            width: 100%;
            //font-family: @MyriadProIt;
            font-size: 20px;
            line-height: 1.2;
            color: #FFFFFF;
            padding: 0 0 0 0;
            margin: 5px 0 0 15px;
            vertical-align: top;
            a{
                color: #FFFFFF;
                text-decoration: underline;
            }
        }
    }
    .block-checkbox.error{
        .checkbox{
            /*            label{
                            &:before{
                                content: '';
                                //background: url(/images/i-checkbox-error.png) no-repeat;
                                //background-size: contain;
                                display: inline-block;
                                width: 1.389vw;
                                height: 1.389vw;
                            }
                        }*/
        }
        label{
            color: red!important;
            font-weight: bold;
            a{
                color: red!important;
            }
        }
    }
    #block-file{
        width: 300px;
        height: 84px;
        border: 2px dashed #FFFFFF;
        border-radius: 5px;
        margin: 50px 0 0 0;
        .file{
            background-color: transparent;
            width: 296px;
            height: 80px;
            border-radius: 5px;
            margin: 0 0 0 0;
            input{
                position: relative;
                width: 300px;
                height: 84px;
                z-index: 1;
                opacity: 0;
            }
            label{
                width: 296px;
                height: 80px;
                margin: 0;
                position: relative;
                top: -84px;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    //font-family: @MyriadProIt;
                    font-size: 20px;
                    line-height: 1;
                    color: #c3c1d3;
                    text-align: center;
                    padding: 0 0 0 0;
                    margin: 0 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .fileName{
                    width: 300px;
                    height: 84px;
                    //font-family: @MyriadProIt;
                    font-size: 20px;
                    line-height: 1;
                    padding: 0 0 0 0;
                    margin: 0 0 0 0;
                }
            }
        }
    }
    #block-file.error{
        border-color: #E20019;
        .file{
            //background-color: transparent;
            label{
                p{
                    color: #E20019;
                }
            }
        }
    }
    /*    .block-textarea{
            width: 300px;
            height: 284px;
            label{
                color: #9D9D9D!important;
            }
            .MuiInputBase-inputMultiline{
                position: relative;
                top: 15px!important;
                left: 15px!important;
                width: 260px;
                height: 244px!important;
                resize: none;
                padding: 0;
                //font-family: @robotoLight;
                font-style: italic;
                font-size: 16px;
                line-height: 1.2;
                color: #000000;
                text-transform: none;
                &:focus{
                    outline: none;
                }
            }
        }
        .block-textarea.error{
            label{
                color: red!important;
                font-weight: bold;
            }
        }*/

    .block-content{
        .top{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            max-width: 1375/16vw;
            width: 100%;
            margin: 70/16vw auto 0 auto;
            padding: 0 0 0 0;
            .top-logo{
                width: 283/16vw;
                img{
                    width: 100%;
                }
            }
            .top-text{
                width: 860/16vw;
                margin: 0 0 0 120/16vw;
                h1{
                    font-family: @MontserratBold;
                    font-size: 50/16vw;
                    color: #ffffff;
                    margin: 0 0 0 0;
                }
                p{
                    font-family: @MontserratRegular;
                    font-size: 26/16vw;
                    color: #ffffff;
                    margin: 15/16vw 0 0 0;
                }
            }
        }
        .survey{
            background: rgba(0, 0, 0, 0.5);
            max-width: 1375/16vw;
            width: 100%;
            margin: 95/16vw auto 145/16vw auto;
            padding: 110/16vw 90/16vw 60/16vw 85/16vw;
            border: 6/16vw solid #999999;
            h1{
                font-family: @MontserratBold;
                font-size: 50/16vw;
                color: #ffffff;
                margin: 0 0 0 0;
                span{
                    font-family: @MontserratLightItalic;
                    font-size: 27/16vw;
                }
            }

            .MuiRadio-root, .MuiCheckbox-root, .Mui-checked{
                color: #ffffff;
                padding: 15/16vw 20/16vw 0 0;
                svg{
                        width: 25/16vw;
                        height: 25/16vw;
                        stroke: #FFFFFF;
                        fill: currentColor;
                        transition: none;
                    }
            }
            .MuiRadio-root .MuiSvgIcon-root path {
                d: path('M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
            }
            .Mui-checked .MuiSvgIcon-root path {
                d: path('M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z');
            }
            .MuiFormControlLabel-root{
                align-items: start;
                p{
                    font-family: @MontserratRegular;
                    font-size: 33/16vw;
                    color: #ffffff;
                    span{
                        font-family: @MontserratLightItalic;
                        font-size: 27/16vw;
                    }
                }
            }
            .MuiFormGroup-root{
                margin: 75/16vw 0 150/16vw 0;
            }
            .flex-direction-row{
                flex-direction: row;
            }
            .answer_comment{
                .block-input{
                    width: 560/16vw;
                    margin: 0;
                }
                input{
                    width: 488/16vw;
                }
            }
            button{
                display: block;
                font-family: @MontserratRegular;
                font-size: 33/16vw;
                color: #ffffff;
                text-transform: none;
                line-height: 1;
                margin: 0 0 0 auto;
                padding: 19/16vw 58/16vw;
                border: 6/16vw solid #ffffff;
            }
        }
    }

    .block-notFound{
        h1{
            text-align: center;
            margin: 0;
        }
    }
}
@import 's1280.less';
@import 's640.less';