@media (max-width: 750px) {
    body{
        /*background-size: 180%;*/
        /*min-height: 693/3.2vw;*/
        /*height: auto;*/
        .block-input, .block-select{
            display: block;
            width: 240/3.2vw;
            height: 30/3.2vw;
            background-color: transparent;
            padding: 0;
            margin: 65/16vw 0 150/16vw 0;
            border: 6/16vw solid #ffffff;
            border-radius: 0px;
            .MuiInput-root{
                border: none;
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                &:after, &:hover, &:not(.Mui-disabled, .Mui-error), &:before{
                    border: none;
                }
            }
            .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
                border: none;
            }
            .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled{
                background: transparent;
                color: #9D9D9D;
                text-transform: none;
                transform: none;
                top: -25px;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
            }
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
            label{
                background: transparent;
                width: 594/16vw;
                max-width: 594/16vw;
                //font-family: @MyriadProIt;
                font-style: italic;
                font-size: 20px;
                line-height: 30px;
                color: #c3c1d3;
                text-transform: none;
                text-align: left;
                transform: translate(0, 0);
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                z-index: 1;
            }
            input{
                background-color: transparent;
                width: 226/3.2vw;
                height: 26/3.2vw;
                font-family: @MontserratRegular;
                font-size: 70/16vw;
                line-height: 57/16vw;
                color: #FFFFFF;
                text-transform: none;
                text-align: left;
                padding: 0 30/16vw;
                margin: 0;
                border-radius: 0px;
            }
        }
        #message{
            .MuiDialog-paper{
                max-width: 256/3.2vw;
                width: 100%;
                height: auto;
                background-color: #FFFFFF;
                border-radius: 10px
            }
            .MuiDialogContent-root{
                width: 256/3.2vw;
                margin: 0 auto;
                padding: 0 0 0 0;
            }
            .modal-i-close{
                position: absolute;
                width: 15px;
                height: 15px;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }
            h1{
                width: 100%;
                //font-family: @MyriadProBold;
                font-size: 40px;
                line-height: 1;
                color: #000000;
                text-align: center;
                text-transform: none;
                margin: 0 0 30px 0;
                padding: 0;
            }
            p{
                font-family: @MontserratRegular;
                font-size: 70/16vw;
                line-height: 1.2;
                color: #000000;
                text-align: center;
                margin: 0;
                padding: 50/16vw 0;
            }
        }
        .block-content{
            .top{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                max-width: 1375/16vw;
                width: 100%;
                margin: 150/16vw auto 0 auto;
                padding: 0 0 0 0;
                .top-logo{
                    width: 283/16vw;
                    img{
                        width: 100%;
                    }
                }
                .top-text{
                    width: 100%;
                    margin: 80/16vw 0 0 0;
                    h1{
                        font-family: @MontserratBold;
                        font-size: 80/16vw;
                        color: #ffffff;
                        margin: 0 0 0 0;
                    }
                    p{
                        width: 65%;
                        font-family: @MontserratRegular;
                        font-size: 56/16vw;
                        color: #ffffff;
                        margin: 15/16vw 0 0 0;
                    }
                }
            }
            .survey{
                background: rgba(0, 0, 0, 0.5);
                max-width: 1375/16vw;
                width: 100%;
                margin: 95/16vw auto 145/16vw auto;
                padding: 110/16vw 90/16vw 60/16vw 85/16vw;
                border: 6/16vw solid #999999;
                h1{
                    font-family: @MontserratBold;
                    font-size: 76/16vw;
                    color: #ffffff;
                    margin: 0 0 0 0;
                    span{
                        font-family: @MontserratLightItalic;
                        font-size: 55/16vw;
                    }
                }

                .MuiRadio-root, .MuiCheckbox-root, .Mui-checked{
                    color: #ffffff;
                    svg{
                        width: 15/3.2vw;
                        height: 15/3.2vw;
                        stroke: #FFFFFF;
                        fill: currentColor;
                        transition: none;
                    }
                }
                .MuiRadio-root,.MuiRadio-root.Mui-checked{
                    padding: 0/3.2vw 5/3.2vw 0 0;
                }
                .MuiCheckbox-root,.MuiCheckbox-root.Mui-checked{
                    padding: 5/3.2vw 5/3.2vw 0 0;
                }
                .MuiRadio-root .MuiSvgIcon-root path {
                    d: path('M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                }
                .Mui-checked .MuiSvgIcon-root path {
                    d: path('M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z');
                }
                .MuiFormControlLabel-root{
                    align-items: start;
                    margin-left: 0; 
                    p{
                        font-family: @MontserratRegular;
                        font-size: 70/16vw;
                        color: #ffffff;
                        span{
                            font-family: @MontserratLightItalic;
                            font-size: 55/16vw;
                        }
                    }
                }
                .MuiFormGroup-root{
                    margin: 75/16vw 0 150/16vw 0;
                }
                .flex-direction-row{
                    flex-direction: row;
                }
                .answer_comment{
                    .block-input{
                        width: 208/3.2vw;
                        margin: 0;
                    }
                    input{
                        width: 194/3.2vw;
                    }
                }
                button{
                    display: block;
                    font-family: @MontserratRegular;
                    font-size: 70/16vw;
                    color: #ffffff;
                    text-transform: none;
                    line-height: 1;
                    margin: 0 0 90/16vw auto;
                    padding: 25/16vw 175/16vw;
                    border: 6/16vw solid #ffffff;
                    border-radius: 0;
                }
            }
        }
    }
}
